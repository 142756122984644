<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Info General</v-tab>
    </v-tabs>

    <v-card flat class="pa-3 mt-2">
      <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="mt-1">
                <v-card-text class="d-flex">
                  <v-avatar color="primary" size="120" class="v-avatar-light-bg primary--text me-6" rounded>
                    <v-img v-if="logoUrl" :src="logoUrl"></v-img>
                    <span v-else class="font-weight-semibold text-5xl">{{ avatarText(company.name) }}</span>
                  </v-avatar>

                  <!-- upload photo -->
                  <div>
                    <v-btn color="primary" class="me-3 mt-5" @click="$refs.filePicker.click()" small>
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Subir logo</span>
                    </v-btn>

                    <input
                      ref="filePicker"
                      @change="preUploadLogo($event.target.files)"
                      type="file"
                      accept="image/png, image/jpg, image/gif, image/jpeg"
                      :hidden="true"
                    />

                    <v-btn color="error" outlined class="mt-5" @click=";(logoUrl = ''), (company.logo = '')" small>
                      Limpiar
                    </v-btn>
                    <p class="text-sm mt-5">Formatos permitidos JPG, GIF or PNG.</p>
                  </div>
                </v-card-text>
                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.rut"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="RUT"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.name"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="Razón Social"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.fantasyName"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="Nombre de Fantasía"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.address"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="Dirección"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.phone"
                    outlined
                    dense
                    label="Teléfono"
                    placeholder="2204 9430"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.sector"
                    outlined
                    dense
                    label="Sector"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="company.email"
                    outlined
                    dense
                    :rules="[validators.emailValidator]"
                    label="Email"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4" v-if="company._id">
                  <users-selection
                    v-model="company.defaultContact"
                    :usersList="usersList"
                    label="Contacto"
                    outlined
                    dense
                  ></users-selection>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            type="submit"
            :loading="buttonLoading"
            :disabled="buttonLoading"
            v-if="$can('update', 'company')"
          >
            Guardar
          </v-btn>
          <v-btn color="secondary" outlined :to="{ name: 'apps-company-list' }"> Volver </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import { avatarText } from '@core/utils/filter'
import { required, requiredSelect, emailValidator } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, watch, nextTick } from 'vue'

import store from '@/store'

import UsersSelection from '@/views/components/UsersSelection.vue'

export default {
  components: {
    UsersSelection,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const company = ref(JSON.parse(JSON.stringify(props.companyData)))

    const tab = ref('')

    const valid = ref(false)
    const form = ref(null)
    const buttonLoading = ref(false)
    const logoUrl = ref(null)
    const filePicker = ref(null)
    const usersList = ref([])

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const preUploadLogo = files => {
      if (files.length && files[0].type.includes('image/')) {
        logoUrl.value = URL.createObjectURL(files[0])
      } else {
        snackbarPlugin.showMessage({ content: 'Formato de imagen incorrecto', color: 'error', timeout: 10000 })
      }
    }

    const uploadLogo = async () => {
      if (filePicker.value.files.length) {
        let formdata = new FormData()
        formdata.append('companyId', company.value._id)
        formdata.append('logo', filePicker.value.files[0])

        let r = await store.dispatch('app-company/uploadLogo', formdata)

        if (r.status == 200) {
          nextTick(() => {
            company.value.logo = r.data.logo
            logoUrl.value = store.state.apidomain + '/company/logos/' + company.value.logo + '?rand=' + Date.now()
            filePicker.value = null
          })
          return true
        } else {
          snackbarPlugin.showMessage({ content: r.data.message, color: 'error', timeout: 10000 })
          return false
        }
      } else return true
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        buttonLoading.value = true

        if (company.value._id) {
          await uploadLogo()
          store
            .dispatch('app-company/updateCompany', { company: company.value, companyBkp: props.companyData })
            .then(r => {
              buttonLoading.value = false
              emit('already-save', true)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
            })
            .catch(e => {
              buttonLoading.value = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
        } else {
          store
            .dispatch('app-company/addCompany', company.value)
            .then(async r => {
              company.value = r.data.company
              await uploadLogo()
              buttonLoading.value = false
              emit('already-save', true)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
            })
            .catch(e => {
              buttonLoading.value = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.companyData,
      value => {
        company.value = JSON.parse(JSON.stringify(value))
        if (value.logo) logoUrl.value = store.state.apidomain + '/company/logos/' + value.logo + '?rand=' + Date.now()

        store
          .dispatch('user/fetchUsers', {
            role: 'companySupervisor',
            company: company.value._id,
          })
          .then(response => {
            const { filteredData } = response.data

            usersList.value = filteredData
          })
          .catch(error => {
            console.log(error)
          })
      },
    )

    return {
      filePicker,
      preUploadLogo,
      logoUrl,
      avatarText,
      company,
      form,
      tab,
      onSubmit,
      valid,
      validate,
      validators: { required, requiredSelect, emailValidator },
      buttonLoading,
      usersList,

      // icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
